import React from "react";
import { DropdownDivider, DropdownItem, DropdownMenu, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { ILexia } from "../../story-api";
import FragmentWrapper from "../../story-api/fragment-wrapper";
import { ISubMenuItem, SubMenuItem } from "../menu/MenuTypes";

const StyledMenu = styled(DropdownMenu)`
  & .item:not([tabindex]) {
    display: flex !important;
    justify-content: space-between;
    grid-gap: 2em;
    pointer-events: none;
  }
`;

export function InfoMenuItem(data: {
  lexia: ILexia,
  content: FragmentWrapper
} | undefined): ISubMenuItem & { submenu: () => React.ReactNode } {
  let info: Array<[string, any] | "-"> = [];
  if (data != null) {
    const { lexia, content } = data;
    const { wordCount, charCount, allCharCount } = getStat(content.lexiaDom);
    info = [
      ["Autor", lexia.author],
      ["Data utworzenia", lexia.created],
      ["Data modificacji", lexia.lastModified],
      ["Rozmiar pliku", lexia.byteLength],
      "-",
      ["Liczba słów", wordCount],
      ["Liczba liter", charCount],
      ["Liczba znaków (ze spacjami)", allCharCount]
    ];
    info = info.map(row => {
      if (row === "-") return row;
      if (row[1] == undefined) return null;

      let [key, value] = row;
      if (value instanceof Date) {
        value = value.toLocaleString("pl-PL");
      } else {
        value = value + "";
      }
      return value ? [key, value] : null;
    }).filter(Boolean) as any;
  }

  const copyInfo = () => {
    const text = info.map((row) => row === "-" ? "" : row.join(":\t")).join("\n");
    navigator.clipboard.writeText(text);
  };

  const submenu = () => <StyledMenu>
    { info.map((row, idx) => row === "-" ? <DropdownDivider key={idx} /> :
      <DropdownItem key={row[0]}>
        <b>{ row[0] }:</b> { row[1] }
      </DropdownItem>)
    }
    <DropdownDivider />
    <DropdownItem tabIndex="0" onClick={copyInfo}>
      <Icon name="copy" /> Skopiuj do schowka
    </DropdownItem>
  </StyledMenu>;

  const item: any = SubMenuItem("Informacje", submenu);
  item.disabled = info.length === 0;
  return item;
}

function getStat(dom: Element) {
  const wordCount = countWords(dom);
  const text = (dom.textContent ?? "").replace(/\s+/g, " ");
  const charCount = text.replace(/\W+/g, "").length;
  const allCharCount = text.length;
  return { wordCount, charCount, allCharCount };
}

function countWords(node: ChildNode): number {
  switch (node.nodeType) {
    case Node.ELEMENT_NODE:
      return Array
        .from(node.childNodes)
        .map(countWords)
        .reduce((a, b) => a + b, 0);
      case Node.TEXT_NODE:
        if (node.textContent) {
          return node.textContent.trim().split(/\W+/).length;
        }
  }
  return 0;
}
