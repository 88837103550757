import { FC, ReactNode } from "react";
import { Header, Icon, Placeholder } from "semantic-ui-react";
import styled, { css } from "styled-components";
import { IHypertextStory } from "../../story-api";
import { Link } from 'gatsby';

type StorySegmentProps = {
  story: IHypertextStory;
  isLoading?: boolean;
  ribbon?: ReactNode;
  to?: string | null;
  children?: ReactNode;
};
const StorySegment: FC<StorySegmentProps> = ({ story, isLoading, to, ribbon, children }) => {
  const content =
    <>
      { isLoading ? null : <SegmentBackground style={{
        backgroundImage: `url(${story.coverImage})`,
      }} /> }
      <TitleArea>
        <Header as="h4">{story.author || "\xa0"}</Header>
        <Header as="h3">{story.title || <i>(bez tytułu)</i>}</Header>
      </TitleArea>
      { isLoading ? <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
          <Placeholder.Line length="short" />
        </Placeholder.Paragraph>
      </Placeholder> : <>
        <Icon name={"feather alternate" as any} />
        { ribbon && <SegmentRibbon>{ribbon}</SegmentRibbon> }
      </> }
    </>;
  const isLink = !isLoading && !!to;
  return <StyledSegment isLink={isLink} isLoading={isLoading}>
    { isLink ?
      <LinkingArea as={Link} to={to}>{content}</LinkingArea> :
      <LinkingArea>{content}</LinkingArea>
    }
    { children }
  </StyledSegment>;
};

const StyledSegment = styled.div<{ isLink: boolean, isLoading?: boolean }>`
  width: 150px;
  height: 212px;
  background-color: #fff;
  box-shadow:
    0 2px 4px 0 rgba(34,36,38,.12),
    0 2px 10px 0 rgba(34,36,38,.15);
  border: 1px solid #ccc;
  border-color: #ccc #888 #888 #ccc;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  transition: 0.3s transform, 0.3s box-shadow, 0.3s border-color;
  position: relative;
  z-index: 3;

  pointer-events: ${props => props.isLoading ? "none" : "auto"};

  ${props => props.isLink && css`
    box-shadow:
      0 2px 4px 0 rgba(34,36,38,.12),
      0 2px 10px 0 rgba(34,36,38,.15),
      3px 2px 0 #ccc;

    &:hover, &:focus-within {
      transform: translate(-2px, -2px);
      box-shadow:
        0 2px 4px 0 rgba(34,36,38,.12),
        0 2px 10px 0 rgba(34,36,38,.15),
        4px 3px 3px #ccc;
    }
  `}
`;

const LinkingArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000 !important;
  font-size: 1rem;
  padding: 1em;
  height: 100%;

  h3, h4 {
    color: #000 !important;
  }

  > .icon {
    width: 100%;
    text-align: center;
    opacity: 0.75;
  }
`;

const SegmentRibbon = styled.div`
  position: absolute;
  top: 130px;
  z-index: 3;
  left: 1em;
  color: #fff;
`;

const SegmentBackground = styled.div`
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  box-shadow:
    inset -1px -1px 1px #0038,
    inset 1px 1px 1px #eef8;
  filter: grayscale(20%);
  opacity: 0.5;
  background-position: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background: linear-gradient(#fff8 25%, transparent 62%);
  }
`;

const TitleArea = styled.div`
  display: grid;
  grid-template-rows: 48px auto;
  text-align: center;
  align-items: center;
  position: relative;

  h3.ui.header {
    margin-bottom: 10px !important;
    overflow: hidden;
    word-wrap: anywhere;
    text-overflow: ellipsis;
  }
`;

export default StorySegment;
