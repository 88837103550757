import { navigate } from 'gatsby';
import { FC, useCallback, useEffect, useId, useMemo, useState } from 'react';
import rawMenu from '../../../../editor/src/menus/preview.menu';
import { useLexia } from '../../queries';
import { MenuLayout } from '../menu/MenuLayout';
import { TCommand, constructMenu } from "../menu/MenuTypes";
import { InfoMenuItem } from './infoMenu';
import { PreviewToolbar } from './toolbar';
import { ILexia, storyApi } from '../../story-api';

const PreviewComponent: FC<{ path: string }> = ({ path: outerPath }) => {
  const iframeId = useId();
  const execCommand = (command: TCommand) => {
    switch (command) {
      case "Print":
        (document.getElementById(iframeId) as HTMLIFrameElement).contentWindow?.print();
        break;
      case "Quit":
        navigate(editUrl);
        break;
      default:
        console.log("execCommand", command);
    }
  };

  const [path, setPath] = useState(outerPath);
  useEffect(() => setPath(outerPath), [outerPath]);

  const [lexia, setLexia] = useState<ILexia>();
  useEffect(() => {
    storyApi.getLexia(path).then((lexia) => {
      setLexia(lexia);
    }).catch((e) => {
      console.error("Error loading lexia metadata:", e);
    });
  }, [path]);

  const editUrl = "/e/" + path;
  const mapUrl = "/m/" + path.replace(/\/[^/]+$/, "/");

  const { data } = useLexia(path);
  const menu = useMemo(() => constructMenu(rawMenu, (name: string) => {
    if (name === "InfoMenu") return InfoMenuItem(data);
  }), [data]);

  const onNavigate: any = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const pathname = event.currentTarget.pathname;
    const newPath = /^.*\/(?:~|[hev]\/)(.*)$/.exec(pathname);
    if (newPath) {
      event.preventDefault();
      setPath(newPath[1]);
    }
  }, []);

  return <MenuLayout
    execCommand={execCommand}
    menu={menu}
    toolbar={<PreviewToolbar editUrl={editUrl} mapUrl={mapUrl} />}
    sidebarProps={{ lexia, onNavigate }}
  >
    <iframe id={iframeId} src={"/~" + path} role="application" />
  </MenuLayout>;
};

export default PreviewComponent;
