import StoryList from "../components/browse/StoryList";
import PreviewComponent from "../components/preview/preview";

const PreviewPage = (props: { "*": unknown }) => {
  if (typeof document === "undefined") return;
  const path = props["*"] + "";
  if (path) {
    return <PreviewComponent path={path} />;
  } else {
    return <StoryList />;
  }
};

export default PreviewPage;
