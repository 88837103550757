/* eslint-disable */
import raw_i_help from "./help.menu";


function menu(Command, Link, Submenu, Divider, Import) {
	const i_help = raw_i_help(...arguments);
	return [
		Submenu("Plik", [
			Command("Drukuj...", "Print", "print"),
			Command("Powrót do edytora", "Quit", "arrow left")
		]),
		Submenu("Edytuj", [
			Command("Kopiuj", "Copy", "copy"),
			Command("Wklej", "Paste", "paste"),
			Divider(),
			Command("Zaznacz wszystko", "SelectAll", "content"),
			Divider(),
			Command("Znajdź i zamień...", "Search", "search")
		]),
		Import("InfoMenu"),
		i_help
	];
};

export default menu;
