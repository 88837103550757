import { Link } from 'gatsby';
import { FC } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { ToolbarSplit } from '../menu/MenuBase';
import { useSidebarSetter } from '../menu/SidebarContext';
import { NeighborsMapSidebar } from '../sidebars/NeighborsMap';

type PreviewToolbarProps = {
  editUrl: string;
  mapUrl: string;
};
export const PreviewToolbar: FC<PreviewToolbarProps> = ({ editUrl, mapUrl }) => {
  const showNeighborsSidebar = useSidebarSetter(NeighborsMapSidebar);
  return <>
    <Button basic size="small" as={Link} to={editUrl}>
      <Icon name="arrow left" />
      Edytuj
    </Button>
    <Button basic size="small" as={Link} to={mapUrl}>
      <Icon name="globe" />
      Mapa
    </Button>
    <ToolbarSplit />
    <Button basic size="small" onClick={() => setTimeout(showNeighborsSidebar)}>
      <Icon name="sitemap" />
      Sąsiedztwo
    </Button>
  </>;
};
