import { FC, useEffect, useState } from 'react';
import { Input, List } from "semantic-ui-react";
import styled from "styled-components";
import { useStoryList } from "../../queries";
import { IHypertextStory } from '../../story-api';
import Layout from "../layout/Layout";
import PageHeader from '../layout/PageHeader';
import PleaseWaitComponent from '../misc/pleaseWaitComponent';
import StorySegment from '../stories/StorySegment';

const StoryList: FC<{}> = () => {
  const { data: storyList, isLoading, isError, isSuccess } = useStoryList();

  const [storySearchPhrase, setStorySearchPhrase] = useState("");
  const [filteredStoryList, setFilteredStoryList] = useState<IHypertextStory[]>([]);

  useEffect(() => {
    const phrase = storySearchPhrase.toLocaleLowerCase();
    setFilteredStoryList(
      storyList?.filter((story: IHypertextStory) => story.matches(phrase)) || []
    );
  }, [storyList, storySearchPhrase]);

  return <Layout>
    <PageHeader text="Czytelnia">
      <Input
        as={SearchInput}
        label={"Wyszukaj hipertekst"}
        value={storySearchPhrase}
        placeholder="Nazwa lub autor"
        onChange={(_, data) => setStorySearchPhrase(data.value)}
      />
    </PageHeader>
    <StyledList horizontal>
      {isLoading ? <PleaseWaitComponent /> : null}
      {isError ? (
        <div>Nie udało się pobrać listy hipertekstów.</div>
      ) : null}
      {isSuccess
        ? filteredStoryList.map((story, index) => (
            <List.Item as={SpacedListItem} key={story.url.href}>
              <StorySegment story={story} isLoading={isLoading} to={story.url.href + '/'} />
            </List.Item>
          ))
        : null}
    </StyledList>
  </Layout>;
};

const StyledList = styled(List)`
  display: flex !important; // Semantic UI override
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const SpacedListItem = styled.div`
  margin-bottom: 10px;
`;

const SearchInput = styled.label`
  &.ui.input > input:not([type="color"]) {
    padding: .607em 1em;
  }
`;

export default StoryList;
